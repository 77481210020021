import styles from './index.module.less'
import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Image, Popup, Toast} from 'antd-mobile'
import { Checkbox } from 'react-vant';
import tuodanPoster from '../../../images/blind-box/tuodan-poster.jpg'

const sceneCode = 'agreementPopup'
const version = '20240924'

const AgreementPopup = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        //查询本地缓存
        const value = localStorage.getItem(sceneCode)
        if(!value || value != version) {
            setVisible(true)
        }
    }, [])
    const onClickButton = () => {
        if(!checked) {
            Toast.show({content: '请阅读并勾选同意', duration: 2000})
            return
        }
        localStorage.setItem(sceneCode, version)
        setVisible(false)
    }
    const onClickCheckBox = () => {
        setChecked(!checked)
    }
    useImperativeHandle(ref, () => {
        return {
            show: () => {
                setVisible(true)
            }
        }
    })
    return (
        <Popup
            visible={visible}
            bodyStyle={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px', height: `70vh` }}>
            <div className={styles.agreementPopupBody}>
                <div className={styles.title}>用户协议与隐私政策</div>
                <div className={styles.contentArea}>
                    <Image className={styles.contentImage} src={tuodanPoster}/>
                    <div className={styles.contentText}>1.所有纸条均属发布者自愿发布行为。如您将含有联系方式的纸条放入则视为您同意别人通过纸条和您取得联系</div>
                    <div className={styles.contentText}>2.严禁投放低俗色情、虚假诈骗、营销广告、违法犯罪等违规内容的纸条，一经发现将对纸条下架处理，恕不退款</div>
                    <div className={styles.contentText}>3.严禁未经他人同意，投放他人信息，一经核实，后果自负</div>
                    <div className={styles.contentText}>4.如纸条不是本人所留、需要删除的可以联系客服后台删除</div>
                    <div className={styles.contentText}>5.个别联系方式加不上是由于用户关闭微信搜索功能，可以再次抽取或联系客服更换纸条</div>
                    <div className={styles.contentText}>6.切勿轻易转账以防被骗，本平台不承担任何法律责任</div>
                    <div className={styles.contentText}>7.聊天请秉承相互尊重原则，勿过度骚扰</div>
                    <div className={styles.contentText}>8.诚信做人禁止海王行为，仅单身人士可投入纸条</div>
                    <div className={styles.contentText}>9.虚拟产品售出概不退款。售后问题请联系在线客服处理</div>
                    <div className={styles.contentText}>10.平台不存储任何用户隐私信息</div>
                    <div className={styles.contentText}>11.关闭本弹窗即代表您知晓并同意上诉《用户协议》与《隐私政策》</div>
                </div>
                <div className={styles.buttonArea}>
                    <Checkbox className={styles.checkBox} iconSize='16px' checked={checked} shape='square'
                        onClick={onClickCheckBox}>我已阅读并同意《用户协议》与《隐私政策》</Checkbox>
                    <div className={styles.button} onClick={onClickButton}>我知道了</div>
                </div>
            </div>
        </Popup>
    )
})

export default AgreementPopup;