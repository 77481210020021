import styles from './index.module.less';
import '../../theme.css';
import { useLocation } from "react-router-dom";
import { Tabs, Swiper, Button, Toast } from 'antd-mobile'
import qs from "query-string";
import requestHttp from "../../utils/request";
import { useState, useEffect, useRef } from 'react';
import MemberCard from '../../components/member-card';
import InputPopup from '../../components/input-popup';
import UserInfoCard from '../../components/user-info-card';

const defaultTabItems = [
  {
    key: '0',
    title: '白银会员',
    children: [],
    descList: ["每天额外50次捞瓶子机会", "每天额外50次扔瓶子机会", "每天额外30次匹配机会", "会员专属头像标识", "会员去除所有页面广告"]
  },
  {
    key: '1',
    title: '黄金会员',
    children: [],
    descList: ["每天额外100次捞瓶子机会", "每天额外100次扔瓶子机会", "每天额外60次匹配机会", "会员专属头像标识", "会员去除所有页面广告"]
  }
];

let currentCheckedCode = null
let openId = null

function BottleRecharge(props) {
  const {search} = useLocation()
  const {appName, theme, checkedCode, code} = qs.parse(search)
  const [tabItems, setTabItems] = useState(defaultTabItems)
  const [activeIndex, setActiveIndex] = useState(0)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [inputPopupVisible, setInputPopupVisible] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const swiperRef = useRef(null)
  
  document.documentElement.setAttribute('data-prefers-color-scheme', theme)
  const finalAppName = appName ? appName : 'tm_star_pa'

  useEffect(() => {
    document.title = '开通会员';
    requestHttp('/api/getMemberCardConfig')
    .then(res => {
      //构造tabItems
      if(res.data.silverMemberCards) {
        if(checkedCode) {
          res.data.silverMemberCards.forEach(cardInfo => {
            if(cardInfo.code === checkedCode) {
              currentCheckedCode = checkedCode
              cardInfo.checked = true
            }
          })
        }
        defaultTabItems[0].children = res.data.silverMemberCards
      }
      if(res.data.goldMemberCards) {
        if(checkedCode) {
          res.data.goldMemberCards.forEach(cardInfo => {
            if(cardInfo.code === checkedCode) {
              currentCheckedCode = checkedCode
              cardInfo.checked = true
              //切换到第二个tab
              setActiveIndex(1)
              swiperRef.current?.swipeTo(1)
            }
          })
        }
        defaultTabItems[1].children = res.data.goldMemberCards
      }
      if(!currentCheckedCode) {
        //默认选中第一个
        defaultTabItems[0].children[0].checked = true
        currentCheckedCode = defaultTabItems[0].children[0].code
      }
      setTabItems([...defaultTabItems])
    }).catch(err => console.log(err))

    if(null == code) {
      Toast.show({
        icon: 'fail',
        content: '微信授权失败，请重试',
        duration: 2000
      })
    } else {
      //根据code查询用户信息
      requestHttp('/api/queryUserInfoByLoginCode', {
        loginCode: code,
        appName: finalAppName
      }).then((res) => {
        if(res.data) {
          openId = res.data.openId
          if(res.data.userInfo) {
            setUserInfo(res.data.userInfo)
          } else {
            setInputPopupVisible(true)
          }
        } else {
          Toast.show({
            icon: 'fail',
            content: '微信授权失败，请重试',
            duration: 2000
          })
        }
      }).catch((errorMsg) => [
        Toast.show({
          icon: 'fail',
          content: '微信授权失败，请重试',
          duration: 2000
        })
      ])
    }
  }, [])

  const onClickInputPopupButton = (inputValue) => {
    //查询该用户ID是否有效
    requestHttp('/api/getUserInfoById', {
      userId: inputValue
    }, 'GET').then(res => {
      setUserInfo(res.data)
      setInputPopupVisible(false)
    }).catch(errorMsg => {
      Toast.show({
        icon: 'fail',
        content: '用户ID不存在，请重新输入',
        duration: 2000
      })
    })
  }

  const onClickSwitchUserButton = () => {
    setInputPopupVisible(true)
  }

  const onTabChange = (key) => {
    const index = tabItems.findIndex(item => item.key === key)
    setActiveIndex(index)
    swiperRef.current?.swipeTo(index)
  }

  const onSwiperIndexChange = (index) => {
    setActiveIndex(index)
  }

  const onClickMemberCard = (cardInfo) => {
    currentCheckedCode = cardInfo.code
    tabItems[0].children.forEach(item => {
      if(item.code === cardInfo.code) {
        item.checked = true
      } else {
        item.checked = false
      }
    })
    tabItems[1].children.forEach(item => {
      if(item.code === cardInfo.code) {
        item.checked = true
      } else {
        item.checked = false
      }
    })
    setTabItems([...tabItems])
  }

  const onBridgeReady = (data) => {
    window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": data.appId,     //公众号ID，由商户传入     
        "timeStamp": data.timeStamp,     //时间戳，自1970年以来的秒数     
        "nonceStr": data.nonceStr,      //随机串     
        "package": data.packageVal,
        "signType": data.signType,     //微信签名方式：     
        "paySign": data.paySign //微信签名 
    },
    (res) => {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            Toast.show({
              icon: 'success',
              content: '支付成功',
              duration: 1000
            })
        } else if(res.err_msg === "get_brand_wcpay_request:cancel") {
            //支付取消, do nothing
        } else {
          Toast.show({
            icon: 'fail',
            content: res.err_msg,
            duration: 2000
          })
        }
    })
  }

  const onClickOpenButton = () => {
    if(null == openId) {
      Toast.show({
        icon: 'fail',
        content: '微信授权失败，请重试',
        duration: 2000
      })
      return
    }
    if(null == userInfo.userId) {
      setInputPopupVisible(true)
      return
    }
    setButtonLoading(true)
    requestHttp('/api/createMemberOrderH5', {
      appName: finalAppName,
      openId: openId,
      userId: userInfo.userId,
      code: currentCheckedCode
    }, 'POST').then(res => {
      setButtonLoading(false)
      if (typeof WeixinJSBridge === "undefined") {
        if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', () => onBridgeReady(res.data), false);
        } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', () => onBridgeReady(res.data));
            document.attachEvent('onWeixinJSBridgeReady', () => onBridgeReady(res.data));
        }
      } else {
          onBridgeReady(res.data);
      }
      
    }).catch(errorMsg => {
      setButtonLoading(false)
      Toast.show({
        icon: 'fail',
        content: errorMsg ? errorMsg : '支付失败',
        duration: 1000
      })
    })
  }

  const onInputPopupMaskClick = () => {
    if(null != userInfo.userId) {
      setInputPopupVisible(false)
    }
  }

  return (
    <div className={`${styles.pageBody}`}>
      {userInfo.userId != null && <div>
        <div className={styles.userInfoHintMsg}>正在为当前用户充值</div>
        <UserInfoCard
          theme={theme} 
          userNick={userInfo.userNick} 
          avatar={userInfo.userIconUrl}
          userId={userInfo.userId}
          gender={userInfo.gender}
          buttonName="切换账户"
          onClickButton={onClickSwitchUserButton}/>
      </div>}
      <Tabs
        className={styles.tabsStyle}
        defaultActiveKey={tabItems[0].key}
        activeKey={tabItems[activeIndex].key}
        onChange={onTabChange}>
          {tabItems.map(item => <Tabs.Tab key={item.key} title={item.title} />)}
      </Tabs>
      <Swiper
        direction='horizontal'
        loop
        indicator={() => null}
        ref={swiperRef}
        defaultIndex={activeIndex}
        onIndexChange={onSwiperIndexChange}
      >
        {
          tabItems.map(tabItem => {
            return (
              <Swiper.Item key={tabItem.key}>
                <div className={styles.memberCardWrapper}>
                  {tabItem.children.length > 0 && tabItem.children.map(
                    cardInfo => <MemberCard theme={theme} key={cardInfo.code} cardInfo={cardInfo} onClick={onClickMemberCard}/>)}
                </div>
                <div>
                  <div className={styles.memberDecWrapper}>
                      <div className={styles.memberDescTitleLeft}></div>
                      <div className={styles.memberDescTitle}>{tabItem.title + '特权'}</div>
                  </div>
                  {tabItem.descList.map(desc => {
                    return (
                      <div key={desc} className={styles.memberDescTextWrapper}>
                        <div className={styles.smallPoint}></div>
                        <div className={styles.memberDescText}>{desc}</div>
                      </div>
                    )
                  })}
                </div>
              </Swiper.Item>
            )
          })
        }
      </Swiper>
      <Button className={styles.openButton}
        loading={buttonLoading} 
        block 
        color='primary'
        size='large'
        onClick={onClickOpenButton}>立即开通</Button>
      <div className={styles.noticeDesc}>如支付完成后一直没有生效，退出小程序重新进入即可</div>
      <InputPopup 
        theme={theme}
        visible={inputPopupVisible}
        onClickButton={onClickInputPopupButton}
        onMaskClick={onInputPopupMaskClick}
        title='请输入需要充值的用户ID'
        subTitle='用户ID可在小程序内"我的"页面查看'
        placeholder='请输入用户ID'/>
    </div>
  );
}
  
export default BottleRecharge;